'use client'

import { maxScreenSize } from 'components/ui/basic/Image'
import { Heading } from 'components/ui/text/Heading'
import React, { ComponentType } from 'react'
import { PlayCircle } from 'react-feather'
import { isTabletScreen } from 'shared/domHelpers'
import { checkImage } from 'shared/imageHelpers'
import { newsPath } from 'shared/urlHelpers'
import { News } from 'types'
import NewsMeta from './NewsMeta'
import Image from 'components/ui/basic/Image'
import Link from 'components/router/Link'

const HighlightedItem: ComponentType<{
  item: News
  video: boolean
  priority?: boolean
}> = ({ item, video, priority = false }) => {
  const isTablet = isTabletScreen()
  return video ? (
    <Link href={newsPath(item)} className="view-vertical hover:text-current">
      <div className="relative flex items-center justify-center">
        <Image
          src={checkImage(item.feature_image)}
          className="w-full h-full aspect-w-16 aspect-h-9 rounded overflow-hidden shadow"
          srcSet={[[400, maxScreenSize]]}
          priority={priority}
          objectPosition="top"
        />
        <PlayCircle
          size="4em"
          strokeWidth="1px"
          className="absolute shadow-lg"
        />
      </div>
      <div className="view-vertical">
        <NewsMeta news={item} className="my-4" />
        <Heading level={3} weight="medium" size="base" className="mb-0">
          {item.title}
        </Heading>
        {item.excerpt && <p className="line-clamp-4">{item.excerpt}</p>}
        <div className="block w-24 h-0.5 bg-primary my-8"></div>
      </div>
    </Link>
  ) : (
    <>
      {isTablet ? (
        <Link
          href={newsPath(item)}
          className="view-vertical hover:text-current"
        >
          <div className="relative flex items-center justify-center">
            <Image
              src={checkImage(item.feature_image)}
              className="rounded overflow-hidden shadow w-full h-full aspect-w-16 aspect-h-9"
              srcSet={[[500, maxScreenSize]]}
              objectPosition="top"
            />
          </div>
          <div className="view-vertical">
            <NewsMeta news={item} className="my-4" />
            <Heading level={3} weight="medium" size="base">
              {item.title}
            </Heading>
            {item.excerpt && <p className="line-clamp-4">{item.excerpt}</p>}
            <div className="block w-24 h-0.5 bg-primary my-6"></div>
          </div>
        </Link>
      ) : (
        <Link
          href={newsPath(item)}
          className="flex space-x-0 md:space-x-8 mb-10 hover:text-current"
        >
          <div className="flex-1">
            <Image
              src={checkImage(item.feature_image)}
              className="w-full h-full aspect-w-16 aspect-h-9 rounded overflow-hidden shadow"
              srcSet={[[500, maxScreenSize]]}
              objectPosition="top"
            />
          </div>
          <div className="flex-1 w-5/12">
            <NewsMeta news={item} className="mb-4" />
            <Heading level={3} weight="bold" size="xl">
              {item.title}
            </Heading>
            <div className="block w-24 h-0.5 bg-primary my-4"></div>
            {item.excerpt && (
              <p className="text-sm line-clamp-4">{item.excerpt}</p>
            )}
          </div>
        </Link>
      )}
    </>
  )
}

export default HighlightedItem
