import React from 'react'

const locale = 'de-DE'

export const formattedDateTimeFull = (
  timestamp: number
): React.ReactElement => {
  const formatter = Intl.DateTimeFormat(locale, {
    weekday: 'long',
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    timeZone: 'Europe/Berlin'
  })

  return (
    <span suppressHydrationWarning>
      {timestamp ? formatter.format(new Date(timestamp)) : 'Unbekannt'}
    </span>
  )
}

export const formattedTime = (timestamp: number): React.ReactElement => {
  const formatter = Intl.DateTimeFormat(locale, {
    hour: '2-digit',
    minute: '2-digit',
    timeZone: 'Europe/Berlin'
  })

  return (
    <span suppressHydrationWarning>
      {timestamp ? formatter.format(new Date(timestamp)) : '--:--'}
    </span>
  )
}

export const formattedDate = (timestamp: number): React.ReactElement => {
  const formatter = Intl.DateTimeFormat(locale, {
    weekday: 'long',
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
    timeZone: 'Europe/Berlin'
  })

  return (
    <span suppressHydrationWarning>
      {timestamp ? formatter.format(new Date(timestamp)) : 'Unbekannt'}
    </span>
  )
}

export const formattedDateFull = (timestamp: number): React.ReactElement => {
  const formatter = Intl.DateTimeFormat(locale, {
    weekday: 'long',
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
    timeZone: 'Europe/Berlin'
  })

  return (
    <span suppressHydrationWarning>
      {timestamp ? formatter.format(new Date(timestamp)) : 'Unbekannt'}
    </span>
  )
}

export const formattedDateFullShort = (
  timestamp: number
): React.ReactElement => {
  const formatter = Intl.DateTimeFormat(locale, {
    weekday: 'short',
    day: 'numeric',
    month: 'numeric',
    year: '2-digit',
    timeZone: 'Europe/Berlin'
  })

  return (
    <span suppressHydrationWarning>
      {timestamp ? formatter.format(new Date(timestamp)) : 'Unbekannt'}
    </span>
  )
}

export const formattedDay = (timestamp: number): React.ReactElement => {
  const formatter = Intl.DateTimeFormat(locale, {
    weekday: 'short',
    timeZone: 'Europe/Berlin'
  })

  return (
    <span suppressHydrationWarning>
      {timestamp ? formatter.format(new Date(timestamp)) : '---'}
    </span>
  )
}

export const getAge = (bd: number): string => {
  const today = new Date()
  const birthday = new Date(bd)

  const currentYear = today.getFullYear()
  const currentMonth = today.getMonth()
  const currentDate = today.getDate()

  const bdYear = birthday.getFullYear()
  const bdMonth = birthday.getMonth()
  const bdDate = birthday.getDate()

  let diff = currentYear - bdYear
  if (bdMonth > currentMonth) diff--
  else if (bdMonth === currentMonth && bdDate > currentDate) diff--

  return diff.toString()
}

export const formatNumber = (number: number): string =>
  number.toLocaleString(locale)
